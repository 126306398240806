import React, { useState, useEffect, useRef } from 'react';

const Masonry = () => {
  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  // Reference for the modal content
  const modalRef = useRef();

  // Data for images and descriptions
  const galleryData = [
    {
      id: 1,
      title: 'Image 1 Title',
      image: 'https://plus.unsplash.com/premium_photo-1663855531126-3321495767b1?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Thumbnail image in the gallery
      modalImage: 'https://images.unsplash.com/photo-1727976823180-314b097d2572?q=80&w=2068&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Different image for the modal
      description: 'This is the description for image 1. Add more details here!',
      link: 'https://example.com/1',
    },
    {
      id: 2,
      title: 'Image 2 Title',
      image: 'image2-thumbnail.jpg',
      modalImage: 'image2-large.jpg',
      description: 'This is the description for image 2. Add more details here!',
      link: 'https://example.com/2',
    },
    {
      id: 3,
      title: 'Image 3 Title',
      image: 'image3-thumbnail.jpg',
      modalImage: 'image3-large.jpg',
      description: 'This is the description for image 3. Add more details here!',
      link: 'https://example.com/3',
    },
    {
      id: 4,
      title: 'Image 4 Title',
      image: 'image4-thumbnail.jpg',
      modalImage: 'image4-large.jpg',
      description: 'This is the description for image 4. Add more details here!',
      link: 'https://example.com/4',
    },
    // Add more images as needed
  ];

  // Open modal with image data
  const openModal = (imageData) => {
    setModalData(imageData);
    setIsModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Close modal if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    // Add event listener
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Clean up event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <div className="container mx-auto px-4 py-6 bg-red-400">
      {/* Gallery */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-10">
        {galleryData.map((item) => (
          <div key={item.id} className="gallery-item">
            <img
              src={item.image}  // Thumbnail image for the gallery
              alt={`Image ${item.id}`}
              className="w-full h-auto rounded-lg cursor-pointer transition-opacity opacity-0 duration-500 ease-in-out"
              loading="lazy"
              onLoad={(e) => e.target.classList.remove('opacity-0')}  // Fade-in effect on load
              onClick={() => openModal(item)}  // Open modal with modal image data
            />
          </div>
        ))}
      </div>

      {/* Lightbox Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75">
          <div
            ref={modalRef}
            className="bg-white p-6 rounded-lg max-w-2xl w-full relative"
          >
            {/* Image with a height of 50% of the viewport height */}
            <img
              src={modalData.modalImage}  // Different image for the modal
              alt="Modal Content"
              className="w-full h-[50vh] object-contain rounded-lg mb-4"
            />

            {/* Modal Title */}
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">{modalData.title}</h2>

            {/* Description Paragraph */}
            <p className="text-lg text-gray-700 mb-4 leading-relaxed">{modalData.description}</p>

            {/* Link to External Page */}
            <a
              href={modalData.link}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
            >
              View More
            </a>

            {/* Close Button */}
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-white text-3xl"
            >
              ×
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Masonry;
